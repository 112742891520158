

h1 {
    color: #fff;
    text-align: center;
    width: 100%;
    font-family: 'Assistant', sans-serif;
  }
  
img {
    padding-left: 40%;
    width: 20%;
}